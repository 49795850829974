body {
  margin-top: 30px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overflow: hidden;
  background-color: "#F5F5F5";
}

.header-fixed {
  width: 100%;
  height: 700px;
  overflow-y: scroll;
}

.calendarHeader {
  position: sticky;
  top: 0px;
  z-index: 50;
}

#main-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
}

.modal .modal-content {
  border-radius: 0px;
  border: 0px solid;
  margin: 0px 15px 0px 15px;
}

.slider {
  width: 100%;
}

.bt {
  width: 100%;
  margin: 0px 0px 15px 0px;
  text-align: center;
  padding: 7px;
}

.btHorizontal {
  width: 45%;
  margin: 0px 0px 15px 0px;
  text-align: center;
  padding: 7px;
}

.btOK {
  background-color: #53afb9;
  color: white;
}

.btCANCEL {
  background-color: transparent;
  color: grey;
  border: 1px grey solid !important;
}

.btREMOVE {
  background-color: #da3a42;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#menubar {
  background-color: #004a7b;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #98989c;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 24px;
}

.roundedInput {
  padding: 20px;
  border-radius: 10px;
}

.availableCredits {
  margin: 20px;
}

.availableCard {
  border-radius: 5px;
  border: 1px grey solid;
}

.totalWeek {
  text-align: center;
  font-weight: bold;
  height: 43px;
  top: 0px;
  bottom: 0px;
  right: 3px;
  left: 3px;
  background-color: #53afb9;
  padding-top: 12px;
  border-radius: 3px;
  color: white;
  box-shadow: 0px 2px 4px grey;
  width: 30px;
  margin-right: 3px;
}

.monthWeek {
  text-align: center;
  font-size: 13px;
  font-weight: bold;

  top: 0px;
  bottom: 20px;
  right: 3px;
  left: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-color: #53afb9;
  border-width: 1px;
  border-style: solid;
  color: #53afb9;
  box-shadow: 0px 2px 4px grey;
  width: 30px;
  margin-right: 3px;
}

.monthWeekCount {
  text-align: center;

  font-size: 15px;
  top: 20px;
  bottom: 0px;
  right: 3px;
  left: 3px;
  background-color: #53afb9;
  border-radius: 3px;
  color: white;
  box-shadow: 0px 2px 4px grey;
  width: 30px;
  margin-right: 3px;
}

.totalWeekend {
  text-align: center;
  font-weight: bold;
  height: 43px;
  top: 0px;
  bottom: 0px;
  right: 3px;
  left: 3px;
  background-color: #d6d6d6;
  padding-top: 12px;
  border-radius: 3px;
  color: black;
  box-shadow: 0px 2px 4px grey;
  width: 30px;
  margin-left: 3px;
}

.monthWeekend {
  text-align: center;
  font-size: 13px;
  font-weight: bold;

  top: 0px;
  bottom: 20px;
  right: 3px;
  left: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-color: #d6d6d6;
  border-width: 1px;
  border-style: solid;
  color: black;
  box-shadow: 0px 2px 4px grey;
  width: 30px;
  margin-left: 3px;
}

.monthWeekendCount {
  text-align: center;
  font-size: 15px;
  top: 20px;
  bottom: 0px;
  right: 3px;
  left: 3px;
  background-color: #d6d6d6;
  border-radius: 3px;
  color: black;
  box-shadow: 0px 2px 4px grey;
  width: 30px;
  margin-left: 3px;
}

.slotBtShadow {
  box-shadow: 0px 3px 4px 2px rgb(228, 228, 228);
}

.bm-menu-wrap {
  padding-bottom: 100px;
  font-size: 1.15em;
}

/* Color/shape of burger icon bars on hover
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases

.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles
.bm-menu {
  background: #373a47;
  background-image: linear-gradient(to right, #373a47 , rgba(108, 108, 108, 0.79));
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic 
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list
.bm-item-list {
  color: #ffffff;
  padding: 0.8em;
}

/* Individual item
.bm-item {
  display: inline-block;
}

/* Styling of overlay
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

/* ///////// CALENDAR */

/* declare a 7 column grid on the table */
#calendar {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

#calendar tr,
#calendar tbody {
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  width: 100%;
}

caption {
  text-align: center;
  grid-column: 1 / -1;
  font-size: 130%;
  font-weight: bold;
  padding: 10px 0;
}

#calendar a {
  color: #8e352e;
  text-decoration: none;
}

#calendar td,
#calendar th {
  padding: 5px;
}

#calendar .weekdays th {
  text-align: center;
  text-transform: uppercase;
  line-height: 20px;
  border: none !important;
  padding: 10px 6px;
  color: #fff;
  font-size: 13px;
}

#calendar td {
  min-height: 180px;
  display: flex;
  flex-direction: column;
}

#calendar .days li:hover {
  background: #d3d3d3;
}

#calendar .date {
  text-align: center;
  margin-bottom: 5px;
  flex: 0 0 auto;
  align-self: flex-end;
}

#calendar .event {
  flex: 0 0 auto;
  font-size: 13px;
  border-radius: 4px;
  padding: 5px;
  margin-bottom: 5px;
  line-height: 14px;
  background: #e4f2f2;
  border: 1px solid #b5dbdc;
  color: #009aaf;
  text-decoration: none;
}

#calendar .event-desc {
  color: #666;
  margin: 3px 0 7px 0;
  text-decoration: none;
}

#calendar .other-month {
  background: #f5f5f5;
  color: #666;
}

#Available_credits_for_this_month th,
td {
  text-align: center;
  font-weight: bold;
}

/* ============================
				Mobile Responsiveness
   ============================*/

@media (max-width: 768px) {
  #calendar .weekdays,
  #calendar .other-month {
    display: none;
  }

  #calendar li {
    height: auto !important;
    border: 1px solid #ededed;
    width: 100%;
    padding: 10px;
    margin-bottom: -1px;
  }

  #calendar,
  #calendar tr,
  #calendar tbody {
    grid-template-columns: 1fr;
  }

  #calendar tr {
    grid-column: 1 / 2;
  }

  #calendar .date {
    align-self: flex-start;
  }
}

/* MOBILE CALENDAR */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  font-family: "Montserrat", sans-serif;
  text-rendering: optimizeLegibility;
  background: #fcfcfc;
}

.mock-up-link {
  display: none;
}
@media (min-width: 768px) {
  .mock-up-link {
    display: block;
  }
}

.main-container-wrapper {
  background-color: #f8fafa;
  min-width: 320px;
  min-height: 568px;
  max-width: 414px;
  overflow-y: auto;
}
@media (min-width: 415px) {
  .main-container-wrapper {
    -moz-box-shadow: 0px 32px 47px rgba(32, 23, 23, 0.09);
    -webkit-box-shadow: 0px 32px 47px rgba(32, 23, 23, 0.09);
    box-shadow: 0px 32px 47px rgba(32, 23, 23, 0.09);
    margin: 24px auto;
  }
}

header {
  background-color: #fff;
  display: flex;
  height: 58px;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
}

.header__btn {
  background-color: #86d8c9;
  border: 2px solid #fff;
  border-radius: 50%;
  -moz-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  height: 80px;
  padding-top: 18px;
  position: absolute;
  top: -25px;
  width: 80px;
}
.header__btn:hover,
.header__btn:focus {
  background: #67cebb;
  transition: all 0.3s ease-in;
  outline: none;
}
.header__btn .icon {
  display: inline-block;
}
.header__btn--left {
  left: -25px;
  padding-left: 38px;
  text-align: left;
}
.header__btn--right {
  padding-right: 32px;
  right: -25px;
  text-align: right;
}

.selectedCalendarItem {
  background: #53afb9;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  transition: 0.2s all ease-in;
}

.calendar-container {
  background-color: #fff;
  padding: 16px;
  margin-bottom: 24px;
}
.calendar-container__header {
  display: flex;
  justify-content: space-between;
}
.calendar-container__btn {
  background: transparent;
  border: 0;
  cursor: pointer;
  font-size: 16px;
  outline: none;
  color: #e9e8e8;
}
.calendar-container__btn:hover,
.calendar-container__btn:focus {
  color: #9faab7;
  transition: all 0.3s ease-in;
}
.calendar-container__title {
  color: #222741;
  font-size: 20px;
  font-weight: 700;
}

.calendar-table {
  margin-top: 12px;
  width: 100%;
}
.calendar-table__item {
  border: 1px solid transparent;
  border-radius: 50%;
  color: #333333;
  font-size: 14px;
  font-weight: 700;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 2px;
}

.calendar-table__row {
  display: flex;
  justify-content: center;
}
.calendar-table__header {
  border-bottom: 1px solid rgb(214, 214, 214);
  margin-bottom: 0px;
}

.calendar-table__header .calendar-table__col {
  display: inline-block;
  color: #53afb9;
  font-size: 16px;
  font-weight: 900;
  padding: 12px 3px;
  text-align: center;
  text-transform: uppercase;
  width: 40px;
  height: 38px;
}
@media (min-width: 360px) {
  .calendar-table__header .calendar-table__col {
    width: 46px;
  }
}
@media (min-width: 410px) {
  .calendar-table__header .calendar-table__col {
    width: 54px;
  }
}
.calendar-table__body .calendar-table__col {
  width: 40px;
  height: 42px;
  padding-bottom: 2px;
}
@media (min-width: 360px) {
  .calendar-table__body .calendar-table__col {
    width: 46px;
    height: 48px;
  }
}
@media (min-width: 410px) {
  .calendar-table__body .calendar-table__col {
    width: 54px;
    height: 56px;
  }
}
.calendar-table__today .calendar-table__item {
  border-color: #53afb9;

  color: black;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}
.calendar-table__event .calendar-table__item {
  background-color: #66dcec;
  border-color: #fefefe;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  color: #fff;
}
.calendar-table__event--long {
  overflow-x: hidden;
}
.calendar-table__event--long .calendar-table__item {
  border-radius: 0;
  border-width: 2px 0;
}
.calendar-table__event--start .calendar-table__item {
  border-left: 2px solid #fff;
  border-radius: 50% 0 0 50%;
}
.calendar-table__event--start.calendar-table__col:last-child
  .calendar-table__item {
  border-width: 1px;
}
.calendar-table__event--end .calendar-table__item {
  border-right: 2px solid #fff;
  border-radius: 0 50% 50% 0;
}

.calendar_current_day {
  border-right: 2px solid rgb(0, 0, 0);
  border-radius: 50% 50% 50% 50%;
  padding: 3px;
}
.calendar-table__event--end.calendar-table__col:first-child
  .calendar-table__item {
  border-width: 1px;
}
.calendar-table__inactive .calendar-table__item {
  color: #bfbfbf;
  cursor: default;
}
.calendar-table__inactive .calendar-table__item:hover {
  background: transparent;
  box-shadow: none;
}
.calendar-table__inactive.calendar-table__event .calendar-table__item {
  color: #fff;
  opacity: 0.25;
}
.calendar-table__inactive.calendar-table__event .calendar-table__item:hover {
  background: #66dcec;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}

.events-container {
  padding: 0 15px;
}

.events__title {
  color: #bec1ca;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 16px;
}
.events__tag {
  background: #66dcec;
  border: 2px solid #fefefe;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  color: #fff;
  font-size: 10px;
  font-weight: 600;
  width: 60px;
  margin-left: 16px;
  padding: 5px 2px;
  text-align: center;
}
.events__tag--highlighted {
  background: #fdca40;
}
.events__item {
  background: #fff;
  border-left: 8px solid #86d8c9;
  border-radius: 2px;
  -moz-box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.05);
  padding: 15px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
.events__item--left {
  width: calc(100% - 76px);
}
.events__name {
  font-size: 12px;
  font-weight: 700;
  color: #222741;
  display: block;
  margin-bottom: 6px;
}
.events__date {
  font-size: 12px;
  color: #9faab7;
  display: inline-block;
}
